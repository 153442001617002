





































import {Component, Vue} from "vue-property-decorator";

import HesapNoList from "@/components/comps/lists/HesapNoList.vue";
import IletisimList from "@/components/comps/lists/IletisimList.vue";
import AdresList from "@/components/comps/lists/AdresList.vue";
import Genel from "@/components/vekalet/muvekkil/MuvekkilGenel.vue";
import KimlikBilgileriList from "@/components/comps/lists/KimlikBilgileriList.vue";
import {BorcluListResponseProvider} from "@/services/BorcluService";
import {BorcluEntity} from "@/entity/BorcluEntity";
import TebligatList from "@/components/anakart/tebligat/TebligatList.vue";
import HacizList from "@/components/anakart/haciz/HacizList.vue";

@Component({
    components: {
        HacizList,
        TebligatList,
        KimlikBilgileriList,
        Genel,
        AdresList,
        IletisimList,
        HesapNoList,
    }
})
export default class BorcluDetay extends Vue {

    item: BorcluEntity | null = null;


    mounted() {
        let index: number = parseInt(this.$route.params.id) - 1;
        this.item = BorcluListResponseProvider().data[index];
        console.log("Borçlu: ", this.item);
        console.log("Borçlu kisi kurum: ", this.item.kisiKurum);
    }

}
